import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetWs_TicketToInit, setWs_TicketMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteWs_Ticket } from "services/ws_ticketService";
import moment from "moment";
import { parseJwtToken } from "services/tokenService";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Ws_TicketTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.ws_ticket);
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list ) {
            dispatch(resetWs_TicketToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    },[rData.list.length])
    const handleReset = () => {
        setSearch('');
        dispatch(resetWs_TicketToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteWs_Ticket(rowData.id_ticket);
            if (response) {
                dispatch(resetWs_TicketToInit());
                dispatch(setWs_TicketMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setWs_TicketMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setWs_TicketMessage(''));
        hideShowForm('add');
    }

    const handleStatoTicket = (row) => {
        if(row.stato === 0){
            return (<i className="fa fa-hourglass-end icon-size-normal"></i>) 
        }else if(row.stato === 3){
            return (<i className="fa fa-check icon-size-normal color-icon-green"></i>)
        }else{
            return (<i className="fa fa-times icon-size-normal color-icon-notfound"></i>)
        }
    }


    const valoreDato = (dato) => {
        if(dato == null || dato === ""){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

    return (
        <>
        <div className="d-flex flex-column" >
            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                    <div className="col-sm-9">
                        <h4>Documenti in Attesa</h4>
                    </div>
                    <div className="col-sm-3 text-align-right">
                        
                        <>
                        <Button variant="info" className="" onClick={() => handleReset()}><i className="fa fa-refresh"></i> Aggiorna ({rData.totalCount})</Button>
                        
                        </>
                                        
                    </div>
            </Row>
        </div>
        <div>
            <Col className="ml-auto padding-bottom20" md={4} xs={12} xl={4}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
        </div>
        <div className="d-flex flex-column" >
                
                {rData != null && rData.list.map((row, i) =>{
                    return(
                        <>
                        <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                        <Col md={1} xs={12} xl={1} className="center-block-custom" >
                             {handleStatoTicket(row)}          
                        </Col>
                        
                        <Col md={10} xs={12} xl={10}>
                            <Row>
                                <div className="col-sm-6">Anagrafica: <p><strong>{valoreDato(row.anagrafica)}</strong></p></div>
                                <div className="col-sm-6">Descrizione: <p><strong>{valoreDato(row.descrizione_prodotto)}</strong></p></div>
                                <div className="col-sm-6">Data Ticket: <p><strong>{moment(row.data_ticket).format('DD/MM/YYYY HH:mm:ss')}</strong></p></div>
                                <div className="col-sm-6">Data Aggiornamento: <p><strong>{moment(row.data_aggiornamento).format('DD/MM/YYYY HH:mm:ss')}</strong></p></div>
                            </Row>
                        </Col>
                        
                        </Row>
                        
                    </>

                    )
                })
                
                }
            </div>
        </>
    );
}

